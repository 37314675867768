import React, {useEffect, useState} from 'react';
import './styles.scss';
import {StaticImage} from "gatsby-plugin-image";
import {LocaleType} from "../../../interfaces/Locale";

export default function Benefits({locale}:{locale:LocaleType}){
    const CONTENT = [{
        image: <StaticImage placeholder="blurred" src="../../../images/benefit1.png" alt="steps"/>,
        title: locale["Home.Benefits.Benefit1.title"],
        desc: locale["Home.Benefits.Benefit1.desc"]
    }, {
        image: <StaticImage placeholder="blurred" src="../../../images/benefit2.png" alt="steps"/>,
        title: locale["Home.Benefits.Benefit2.title"],
        desc: locale["Home.Benefits.Benefit2.desc"]}, {
        image: <StaticImage placeholder="blurred" src="../../../images/benefit3.png" alt="steps"/>,
        title: locale["Home.Benefits.Benefit3.title"],
        desc: locale["Home.Benefits.Benefit3.desc"]   }, {
        image: <StaticImage placeholder="blurred" src="../../../images/benefit4.png" alt="steps"/>,
        title: locale["Home.Benefits.Benefit4.title"],
        desc: locale["Home.Benefits.Benefit4.desc"] }]
    return <div>
        <div className="benefits-container">
            <div className="title">
                {locale["Home.Benefits.title"]}
            </div>
            <div className="underline"/>
            <div className="elements-desk">
                <div className="images">
                    {CONTENT.map(c =>
                        <div key={c.title} className="image">
                            {c.image}
                        </div>
                    )}
                </div>
                <div className="ele-titles">
                    {CONTENT.map(c => <div key={c.title} className="ele-title">{c.title}</div>)}
                </div>
                <div className="ele-descs">
                    {CONTENT.map(c => <div key={c.title} className="ele-desc">{c.desc}</div>)}
                </div>

            </div>
            <div className="elements-mob">
                {CONTENT.map(c=><div key={c.title} className="element">
                    <div className="image">{c.image}</div>
                    <div className="ele-title">{c.title}</div>
                    <div className="ele-desc">{c.desc}</div>
                </div>)}
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180"><path fill="#09cdd9" fillOpacity="1" d="M0,160L80,133.3C160,107,320,53,480,37.3C640,21,800,43,960,69.3C1120,96,1280,128,1360,144L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"/></svg>
    </div>

}