import React from "react";
import './styles.scss'
import {StaticImage} from "gatsby-plugin-image";
import {LocaleType} from "../../../interfaces/Locale";

export default function Tests({locale}:{locale:LocaleType}) {
    return <div className="test-container">
        <div className="head">
            <div className="title">
                {locale["Home.Tests.title"]}
            </div>
            <div className="underline"/>
            <div className="text">
                {locale["Home.Tests.desc1"]}
                <br/><br/>
                {locale["Home.Tests.desc2"]}</div>
            <div className="spiral">
                <StaticImage placeholder="blurred" src="../../../images/spiral.png" alt="spiral"/>
            </div>
        </div>
        <div className="elements">
            <div className="element">
                <div className="content">
                    <div className="title">{locale["Home.Tests.test1.title"]}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale["Home.Tests.test1.desc"]}
                        <br/>
                        <br/>
                        <div className="bold-text">{locale["Home.Tests.test1.subheading1.title"]}</div>

                        {locale["Home.Tests.test1.subheading1.desc"]}
                        <br/>
                        <br/>
                        <div className="bold-text">{locale["Home.Tests.test1.subheading2.title"]}</div>
                        {locale["Home.Tests.test1.subheading2.desc"]}
                    </div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/test1.png" alt="test1"/>
                </div>
            </div>
            <div className="element">

                <div className="content">
                    <div className="title">{locale["Home.Tests.test2.title"]}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale["Home.Tests.test2.desc"]}
                        <br/>
                        <br/>
                        <div className="bold-text">{locale["Home.Tests.test2.subheading1.title"]}</div>

                        {locale["Home.Tests.test2.subheading1.desc"]}
                        <br/>
                        <br/>
                        <div className="bold-text">{locale["Home.Tests.test2.subheading2.title"]}</div>
                        {locale["Home.Tests.test2.subheading2.desc"]}
                    </div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/test2.png" alt="test1"/>

                </div>

            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale["Home.Tests.test3.title"]}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale["Home.Tests.test3.desc"]}
                        <br/>
                        <br/>
                        <div className="bold-text">{locale["Home.Tests.test3.subheading1.title"]}</div>

                        {locale["Home.Tests.test3.subheading1.desc"]}
                        <br/>
                        <br/>
                        <div className="bold-text">{locale["Home.Tests.test3.subheading2.title"]}</div>
                        {locale["Home.Tests.test3.subheading2.desc"]}
                    </div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/test3.png" alt="test1"/>

                </div>
            </div>
            <div className="element">

                <div className="content">
                    <div className="title">{locale["Home.Tests.test4.title"]}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale["Home.Tests.test4.desc"]}
                        <br/>
                        <br/>
                        <ol style={{marginLeft:20}}>
                            <li> {locale["Home.Tests.test4.list.element1"]}
                            </li>
                            <li> {locale["Home.Tests.test4.list.element2"]}
                            </li>
                            <li> {locale["Home.Tests.test4.list.element3"]}
                            </li>
                        </ol><br/>
                        {locale["Home.Tests.test4.desc2"]}
                        <br/>
                        <br/>
                        {locale["Home.Tests.test4.desc3"]}

                    </div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/test4.png" alt="test1"/>

                </div>

            </div>
        </div>
        <div className="pattern">
            <StaticImage placeholder="blurred" src="../../../images/dot_pattern3.png" alt="pattern"/>
        </div>
    </div>
}