import React from 'react';
import './styles.scss';
import {StaticImage} from "gatsby-plugin-image";
import {LocaleType} from "../../../interfaces/Locale";


export default function Services({locale}:{locale:LocaleType}) {
    const CONTENT = [{
        image: <StaticImage placeholder="blurred" src="../../../images/step1.png" alt="steps"/>,
        title: locale["Home.Services.Service1.title"],
        desc: locale["Home.Services.Service1.desc"]
    }, {
        image: <StaticImage placeholder="blurred" src="../../../images/step2.png" alt="steps"/>,
        title: locale["Home.Services.Service2.title"],
        desc: locale["Home.Services.Service2.desc"]    }, {
        image: <StaticImage placeholder="blurred" src="../../../images/step3.png" alt="steps"/>,
        title: locale["Home.Services.Service3.title"],
        desc: locale["Home.Services.Service3.desc"]    }, {
        image: <StaticImage placeholder="blurred" src="../../../images/step4.png" alt="steps"/>,
        title: locale["Home.Services.Service4.title"],
        desc: locale["Home.Services.Service4.desc"]    }]

    return <div className="services-div">
        <svg className="vector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
            <path fill="#ffffff" fillOpacity="1"
                  d="M0,0L0 20.6C160,39.3,320,76.3,480,92.6C640,108.3,800,130.3,960,114C1120,98.3,1280,44.3,1540,0L1440,0L0,0Z"/>
        </svg>
        <svg className="vectorPath " stroke="#00BBBE" strokeWidth="3" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 1440 270">
            <path fill="none" fillOpacity="1"
                  d="M0,17L80 29.6C160,39.3,320,76.3,480,92.6C640,108.3,800,120.3,960,94C1120,78.3,1280,30,1440,5"/>
        </svg>

        <div className="services-container">
            <div className="title">
                {locale["Home.Services.title"]}
            </div>
            <div className="underline"/>
            <div className="desc">
                {locale["Home.Services.desc"]}
            </div>
            <div className="elements-desk">
                <div className="images">
                    {CONTENT.map(c =>
                        <div key={c.title} className="image">
                            {c.image}
                        </div>
                    )}
                </div>
                <div className="ele-titles">
                    {CONTENT.map(c => <div key={c.title} className="ele-title">{c.title}</div>)}
                </div>
                <div className="ele-descs">
                    {CONTENT.map(c => <div key={c.title} className="ele-desc">{c.desc}</div>)}
                </div>

            </div>
            <div className="elements-mob">
                {CONTENT.map(c=><div key={c.title} className="element">
                        <div className="image">{c.image}</div>
                        <div className="ele-title">{c.title}</div>
                        <div className="ele-desc">{c.desc}</div>
                    </div>)}
            </div>
        </div>
        <svg className="vector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
            <path fill="#09CDD0" fillOpacity="1"
                  d="M0,192L80,160C160,128,320,80,480,78.7C640,78 ,800,107,960,144C1120,181,1280,203,1360,213.3L1440,224L1440,320L0,320Z"/>
        </svg>
        <svg className="vectorPath2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
            <path fill="none" stroke="#04BCBF" strokeWidth="3"
                  d="M0,182L80,155C160,128,320,80,480,78.7C640,78 ,800,107,960,144C1120,181,1280,203,1560,242.3"/>
        </svg>
    </div>
}