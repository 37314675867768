import React from "react";
import './styles.scss';
import {graphql, useStaticQuery} from "gatsby";
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from "gatsby-plugin-image"
import {LocaleType} from "../../../interfaces/Locale";
export default function Header({locale}:{locale:LocaleType}) {
    const data = useStaticQuery(graphql`
        query MyQuery {
            allImageSharp(filter: {fluid: {originalName: {eq: "headerBG.png"}}}) {
                edges {
                    node {
                        fluid {
                            base64
                            aspectRatio
                            src
                            srcSet
                            srcSetWebp
                            sizes
                        }
                    }
                }
            }
        }   
    `)

    const imageData = data.allImageSharp.edges[0].node.fluid;
    return <BackgroundImage fluid={imageData} className="header-container">
        <div className="navBar">
            <div className="title">{locale['Home.Header.Nav.title']}</div>
            <div className="contact">
                <a href="https://wa.me/13474801260" target="_blank">
                    <div className="phone" >
                        +1 (347) 480-1260

                    </div>
                </a>

                <a href="mailto:info@examremote.com" target="_blank">
                <div className="email">
                    info@examremote.com
                </div>
                </a>
            </div>
        </div>
        <div className="content">
            <div className="textContainer">
                <div className="pattern">
                    <StaticImage placeholder="blurred" src="../../../images/dot_pattern.png" alt="pattern"/>
                </div>
                <h1 className="head">
                    {locale["Home.Header.Content.head"]}

                </h1>
                <div className="text">
                    {locale["Home.Header.Content.text"]}
                    <a href="https://wa.me/13474801260" target="_blank"><button className="btn">{locale["Home.Header.Content.whatsappNow"]}</button></a>
                </div>

            </div>
            <div className="imageContainer">
                <StaticImage placeholder="blurred" src="../../../images/headerIMG.png" alt="GED TEST"/>
            </div>
        </div>

    </BackgroundImage>
}